import Home from '~/pages/Home';
import Shellack from '~/pages/Shellack';
import Gel from '~/pages/Gel';
import Pflege from '~/pages/Pflege';
import Extra from '~/pages/Extra';
import Gallery from '~/pages/Gallery';
import Kontact from '~/pages/Kontact';
import Impressum from '~/pages/Impressum';

import { HeaderOnly } from '~/components/Layouts';

const publicRoutes = [
    { path: '/', component: Home, layout: HeaderOnly },

    { path: '/shellack', component: Shellack, layout: HeaderOnly },
    { path: '/gel', component: Gel, layout: HeaderOnly },
    { path: '/pflege', component: Pflege, layout: HeaderOnly },
    { path: '/extra', component: Extra, layout: HeaderOnly },
    { path: '/gallery', component: Gallery, layout: HeaderOnly },
    { path: '/kontact', component: Kontact, layout: HeaderOnly },
    { path: '/impressum', component: Impressum, layout: HeaderOnly },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
