import { Container, Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './NavbarBase.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function NavbarBase() {
    return (
        <Navbar bg="primary" expand="lg" fixed="top" className={cx('menu')}>
            <Container>
                <Navbar.Brand href="/" className={cx('item')}>
                    <img src={images.logo} width="30" height="30" className={cx('logo')} alt="nvNails" />
                    NAGELSTUDIO
                </Navbar.Brand>
                <Navbar.Brand href="tel:01629609309" className={cx('item')}>
                    Tel:0162 9609309
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navbar-light">
                        <Nav.Link className={cx('item')} href="/shellack">
                            SHELLACK
                        </Nav.Link>
                        <Nav.Link className={cx('item')} href="/gel">
                            GEL-SYSTEM
                        </Nav.Link>
                        <Nav.Link className={cx('item')} href="/pflege">
                            PFLEGE
                        </Nav.Link>
                        <Nav.Link className={cx('item')} href="/extra">
                            EXTRA
                        </Nav.Link>
                        <Nav.Link className={cx('item')} href="/gallery">
                            GALLERY
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className={cx('item')} href="/kontact">
                            Kontact
                        </Nav.Link>
                        <Nav.Link className={cx('item')} href="/impressum">
                            Impressum
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarBase;
