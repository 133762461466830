import classNames from 'classnames/bind';
import styles from './ItemDetail.module.scss';

import Card from 'react-bootstrap/Card';

const cx = classNames.bind(styles);

function ItemDetail({ data }) {
    return (
        <Card className={cx('card')}>
            <Card.Img
                className={cx('card-detail')}
                variant="top"
                style={{
                    backgroundImage: `url(${data.image_path})`,
                }}
            />
            <Card.Body className={cx('card-body')}>
                <Card.Title>{data.name}</Card.Title>
                <Card.Text>{data.description}</Card.Text>
                {data.price && (
                    <Card.Title className={cx('price')}>
                        <span>Nr.{data.id}</span>
                        <span>{data.price}€</span>
                    </Card.Title>
                )}
            </Card.Body>
        </Card>
    );
}

export default ItemDetail;
