import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classNames from 'classnames/bind';
import styles from './Impressum.module.scss';

const cx = classNames.bind(styles);

function Impressum() {
    return (
        <Container fluid="lg" className={cx('cdetail')}>
            <Row className={cx('row')}>
                <Col className="panel-body">
                    <div className="kontact">
                        <h5>Thanh Van Phan</h5>
                        <h5>Web designer</h5>
                        <h5>Hier ist mein Demo website</h5>
                        <p>eine Website erstellen ab 199€</p>
                        <p>Telefon: 0176 95812183</p>
                        <p>Email: vtp5330@gmail.com </p>
                        <p>Otto-Hahn Str.4 </p>
                        <p>64546 Mörfelden-Walldorf </p>
                        <p>Deutschland</p>

                        <p></p>
                        <p></p>
                        <p></p>
                    </div>

                    <div className="stadt">
                        <p>zuständige Aufsichtsbehörde</p>
                        <p>Mörfelden-Walldorf</p>

                        <p>Vertreten durch </p>
                        <p>Van Thanh Phan</p>

                        <p>Inhaltlich verantwortlich im Sinne §6 TDG</p>
                        <p>Van Thanh Phan</p>
                    </div>

                    <div className="content">
                        <p>
                            Alle Markennamen, Warenzeichen, Bild und Text sind Eigentum jeweiliger Inhaber.
                            Haftungsausschluss Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
                            Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr
                            übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                            Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                            Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                            Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                            Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
                            Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                            möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                            umgehend entfernen.
                        </p>

                        <p>
                            Haftung für Links Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
                            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                            Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                            oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
                            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                            der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                            ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                        </p>

                        <p>
                            Datenschutz Die Nutzung unserer Webseite ist in der Regel ohne eine Angabe personenbezogener
                            Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name,
                            Anschrift oder E-Mail-Adresse) erhoben werden, erfolgt dies – soweit es möglich ist– immer
                            auf freiwilliger Basis. Wir geben Ihre Daten ohne Ihre ausdrückliche Zustimmung nicht an
                            Dritte weiter. Außerdem weisen wir Sie darauf hin, dass die Datenübertragung im Internet
                            (wie beispielsweise bei der Kommunikation über E-Mail) Sicherheitslücken aufweisen kann.
                            Denn ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Wir
                            widersprechen hiermit ausdrücklich der Nutzung von im Rahmen der Impressumspflicht
                            veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                            angeforderter Werbung und Informationsmaterialien. Die Betreiber dieser Seiten behalten sich
                            ausdrücklich vor, im Fall der unverlangten Zusendung von Werbeinformationen, etwa durch
                            Spam-Mails, rechtliche Schritte einzuleiten.(Mehr Infos siehe Fußnote Datenschutz)
                        </p>

                        <p>
                            Urheberrecht Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
                            jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                            für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
                            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                            Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                        </p>

                        <p></p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Impressum;
