import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import ItemDetail from '~/components/ItemDetail';
const cx = classNames.bind(styles);

function Test() {
    const [productShow, setProductShow] = useState('');
    const [sliders, setSliders] = useState([]);

    useEffect(() => {
        setTimeout(() => {
            fetch(`https://nvnagel.de/api/sliders`)
                .then((res) => res.json())
                .then((res) => {
                    setSliders(res.data);
                });
        }, 300);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            fetch(`https://nvnagel.de/api/home`)
                .then((res) => res.json())
                .then((res) => {
                    setProductShow(res.data);
                });
        }, 300);
    }, []);

    return (
        <>
            <Container fluid="lg" className={cx('cslider')}>
                <Row>
                    <Col>
                        <Carousel>
                            {sliders &&
                                sliders.map((slider) => (
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            style={{
                                                backgroundImage: `url(${slider.image_path})`,
                                                paddingTop: '50%',
                                                backgroundRepeat: 'none',
                                                backgroundPosition: 'center',
                                                objectFit: 'cover',
                                                backgroundSize: 'cover',
                                            }}
                                            alt=""
                                        />
                                        <Carousel.Caption>
                                            <h2>{slider.text1}</h2>
                                            <h3>{slider.text2}</h3>
                                            <p>{slider.text3}</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                        </Carousel>
                    </Col>
                </Row>
            </Container>

            <Container className={cx('cdetail')}>
                <Row xs={1} sm={2} md={3} lg={4} className={cx('row')}>
                    {productShow &&
                        productShow.map((product) => (
                            <Col>
                                <ItemDetail key={product.id} data={product} />
                            </Col>
                        ))}
                </Row>
            </Container>
        </>
    );
}

export default Test;
