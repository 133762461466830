import { faEarthEurope, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import images from '~/assets/images';
const cx = classNames.bind(styles);

const Footer = () => (
    <footer className="page-footer font-small blue pt-4 bg-info w-100">
        <div className="container-fluid text-center text-md-left">
            <div className="row">
                <div className="col-md-4 mt-md-0 mt-3">
                    <h2 className="text-uppercase">NV Nagelstudio</h2>
                    <p>Farmstraße 16-18</p>
                    <p>64546 Mörfelden-Walldorf</p>
                </div>

                <hr className="clearfix w-100 d-md-none pb-0" />

                <div className="col-md-4 mb-md-0 mb-3">
                    <h3 className="text-uppercase">Öffnungszeiten</h3>
                    <ul className="list-unstyled">
                        <li>
                            <h3>Mo.-Fr. : 10:00 - 19:00 Uhr</h3>
                        </li>
                        <li>
                            <h3>Sa.:Termin nach vereinbarung</h3>
                        </li>
                    </ul>
                </div>

                <div className="col-md-4 mb-md-0 mb-3">
                    <h3 className="text-uppercase">Kontact</h3>
                    <ul className="list-unstyled">
                        <li>
                            <a href="tel:01629609309">
                                <FontAwesomeIcon className={cx('icon')} icon={faPhone} />
                                0162 9609309
                            </a>
                        </li>
                        <li>
                            <a href="http://instagram.com/_u/nv_nagelstudio/">
                                <img src={images.instagram} className={cx('instagram', 'icon')} />
                                nv_nagelstudio
                            </a>
                        </li>

                        <li>
                            <a href="#!">
                                <FontAwesomeIcon className={cx('icon')} icon={faEnvelope} />
                                nvnagelstudio@gmail.com
                            </a>
                        </li>

                        <li>
                            <a href="https://www.nvnails.de/">
                                <FontAwesomeIcon className={cx('icon')} icon={faEarthEurope} />
                                nvnagelstudio.de
                                <h4>nvnails.de</h4>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="footer-copyright text-center py-3">
            © 2022 Copyright:
            <a href=""> vtp5330@gmail</a>
        </div>
    </footer>
);

export default Footer;
