import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './Gel.module.scss';
import ItemDetail from '~/components/ItemDetail';

const cx = classNames.bind(styles);

function Gel() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetch(`https://nvnagel.de/api/gel`)
            .then((res) => res.json())
            .then((res) => {
                setProducts(res.data);
            });
    }, []);

    return (
        <Container fluid="lg" className={cx('cdetail')}>
            <Row className={cx('row')} xs={1} sm={2} md={3} lg={4}>
                {products &&
                    products.map((product) => (
                        <Col>
                            <ItemDetail key={product.id} data={product} />
                        </Col>
                    ))}
            </Row>
        </Container>
    );
}

export default Gel;
