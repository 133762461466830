import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classNames from 'classnames/bind';
import styles from './Kontact.module.scss';

const cx = classNames.bind(styles);

function Impressum() {
    return (
        <Container fluid="lg" className={cx('cdetail')}>
            <Row className={cx('row')}>
                <Col className="panel-body">
                    <div className="panel-body">
                        <div className="break"></div>
                        <h4>
                            <span className="glyphicon glyphicon-home "></span>Address :{' '}
                        </h4>
                        <p>Farmstrasse 16-18 </p>
                        <p>64546 Mörfelden-Walldorf </p>

                        <h4>
                            <span className="glyphicon glyphicon-envelope"></span> Instagram :{' '}
                        </h4>
                        <p>nv_nagelstudio</p>

                        <h4>
                            <span className="glyphicon glyphicon-envelope"></span> Email :{' '}
                        </h4>
                        <p>nvnagelstudio@gmail.com</p>

                        <h4>
                            <span className="glyphicon glyphicon-phone-alt"></span> Telephone :{' '}
                        </h4>
                        <p>Handy:0162 9609309</p>

                        <h4>
                            <span className="glyphicon glyphicon-envelope"></span>Website :{' '}
                        </h4>
                        <p>nvnagelstudio.de</p>
                        <p>nvnails.de</p>

                        <h4>
                            <span className="glyphicon glyphicon-phone-alt"></span> Öffnungszeiten :{' '}
                        </h4>
                        <p>Mo. -Fr.: 10-19 Uhr </p>
                        <p>Sa. : Termin nach vereinbarung </p>

                        <h3>
                            <span className="glyphicon glyphicon-globe"></span>Map
                        </h3>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Impressum;
