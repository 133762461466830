import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import styles from './Shellack.module.scss';
import ItemDetail from '~/components/ItemDetail';
const cx = classNames.bind(styles);

function Shellack() {
    const [productShow, setProductShow] = useState('');

    useEffect(() => {
        setTimeout(() => {
            fetch(`https://nvnagel.de/api/shellack`)
                .then((res) => res.json())
                .then((res) => {
                    setProductShow(res.data);
                });
        }, 300);
    }, []);

    return (
        <Container className={cx('cdetail')}>
            <Row xs={1} sm={2} md={3} lg={4} className={cx('row')}>
                {productShow &&
                    productShow.map((product) => (
                        <Col>
                            <ItemDetail key={product.id} data={product} />
                        </Col>
                    ))}
            </Row>
        </Container>
    );
}

export default Shellack;
